import axios from "axios";
import storageService from "./storageService";

class HttpService {
  constructor() {
    // if (window.location.hostname === "localhost")
    // this.BASE_URL = 'https://localhost:31275/api/';
    // else
    this.BASE_URL = 'https://intelligrade.uno/api/';

    // this.BASE_URL = "https://intelligradebackend.azurewebsites.net/api/";

    // this.BASE_URL = "https://test.intelligradeapi.online/api/";

    this.httpService = axios.create({
      baseURL: this.BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.httpService.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const originalRequest = error.config;
        if ((error.response.status === 401 || (error.response.status === 404 && error.response.data.message === "userNotFound")) && !originalRequest._retry) {
          originalRequest._retry = true;
          if (this.refreshingToken)
            return this.queueRequest(() => {
              originalRequest.headers["Authorization"] =
                "Bearer " + storageService.getToken();
              return this.httpService(originalRequest);
            });
          else return this.refreshAccessToken(originalRequest);
        }
        return Promise.reject(error);
      }
    );

    this.pendingRequests = [];
    this.refreshingToken = false;
  }

  queueRequest(requestFn) {
    if (this.refreshingToken) {
      return new Promise((resolve, reject) => {
        this.pendingRequests.push({ resolve, reject, requestFn });
      });
    } else {
      return requestFn(false);
    }
  }

  async refreshAccessToken(originalRequest) {
    var rememberMe = localStorage.getItem("intelligrade-refreshToken") ? true : false;
    var refreshToken = storageService.getRefreshToken();
    if (!refreshToken)
      refreshToken = localStorage.getItem("intelligrade-refreshToken");
    if (!refreshToken) {
      storageService.removeToken();
      storageService.removeRefreshToken();
      localStorage.removeItem("intelligrade-refreshToken");
    }
    this.refreshingToken = true;

    try {
      const response = await this.httpService.post("auth/refreshtoken", {
        RefreshToken: refreshToken,
      });
      if (response.data.statusCode !== 200) {
        storageService.removeToken();
        storageService.removeRefreshToken();
        localStorage.removeItem("intelligrade-refreshToken");
        window.location.href = "/login?register=false";
      }
      storageService.addToken(response.data.data.accessToken);
      storageService.addRefreshToken(response.data.data.refreshToken);
      if (rememberMe)
        localStorage.setItem("intelligrade-refreshToken", response.data.data.refreshToken);
      this.processPendingRequests();
      originalRequest.headers["Authorization"] =
        "Bearer " + response.data.data.accessToken;
      return this.httpService(originalRequest);
    } catch (error) {
      this.refreshingToken = false;
      storageService.removeToken();
      storageService.removeRefreshToken();
      localStorage.removeItem("intelligrade-refreshToken");
      window.location.href = "/login?register=false";
      return null;
    }
  }

  processPendingRequests() {
    this.refreshingToken = false;
    this.pendingRequests.forEach(({ resolve, reject, requestFn }) => {
      requestFn(true).then(resolve).catch(reject);
    });
    this.pendingRequests = [];
  }

  get(url, token = null) {
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    return this.httpService.get(url, { headers });
  }

  post(url, data, token = null) {
    if (data instanceof FormData) {
      const headers = token
        ? {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }
        : {};
      return this.httpService.post(url, data, { headers });
    } else {
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      return this.httpService.post(url, data, { headers });
    }
  }

  put(url, data, token = null) {
    if (data instanceof FormData) {
      const headers = token
        ? {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }
        : {};
      return this.httpService.put(url, data, { headers });
    } else {
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      return this.httpService.put(url, data, { headers });
    }
  }

  delete(url, token = null) {
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    return this.httpService.delete(url, { headers });
  }
}

export default new HttpService();
